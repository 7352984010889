<template>
  <div
    class="views_shopping_mail_modules_common_sub_navigation_index d-flex align-center flex-wrap"
  >
    <template
      v-for="(item,index) in navigation"
    >
      <div
        :key="index"
        class="item_group d-flex align-center"
      >
        <div class="symbol mx-4"
          v-if="index !== 0"
        > > </div>
        <div class="item cp un_sel"
          @click.stop="changeNavigation({item, index})"
        >{{itemName(item)}}</div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * 导航类型
 * type: page 页面:teaching_package:教学包 task:任务 lecturer 讲师:search:搜索/全部 route:通过路由设置信息
 *
 * page_type: featured 精选 teaching_package 教学包 task 任务
 *
 * search_data: {name: 名称, } 搜索筛选数据
 *
 * package_data: 教学包数据
 *
 * task_data: 任务数据
 *
 * lecturer_data: 讲师数据
 *
 * payment: Boolean 是否开启支付 默认开启
 *
 * route: 路由信息
 *
 * route_data: {navigation_title: '面包屑导航名'}
 */
export default {
  props: {
    navigation: {
      type: Array,
      default: ()=>{
        return [];
      }
    }
  },
  methods: {
    itemName(item){
      const {type, page_type, package_data, task_data, lecturer_data, search_data, route_data} = item;
      if(type === 'page'){
        switch(page_type){
          case('featured'):
            return '精选';
          case('teaching_package'):
            return '教学包';
          case('task'):
            return '任务榜单';
          default:
            return '';
        }
      }
      if(type === 'teaching_package'){

        return package_data?.ppackage?.title ?? package_data.title;
      }
      if(type === 'task'){
        return task_data?.task?.name ?? task_data.name ?? task_data?.caseSubject ?? '';
      }
      if(type === 'search'){
        return search_data.name;
      }
      // console.log(item);
      if(type === 'route'){
        return route_data.navigation_title;
      }
    },
    changeNavigation(params = {}){
      const {item, index} = params;
      const {navigation} = this;
      if(index === (navigation.length - 1)){
        return false;
      }
      const new_navigation = [
        ...new Set([
          ...[],
          ...navigation
        ])
      ].slice(0, index+1);
      this.$api.shopping_mail.changeNav({new_nav: new_navigation});
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_common_sub_navigation_index{
  font-size: 14px;
  color: #999999;
  line-height: 18px;
  .item_group{
    &:last-child{
      .item{
        color: $main_blue;
      }
    }
  }
  .item{
    @include transition;
    &:hover{
      color: $main_blue;
    }
  }
}
</style>
